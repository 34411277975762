<template>
	<div class="search-list">
		<div class="search-list__search-container">
			<or-input
				placeholder="Search"
				class="main-type"
				icon-before="search"
				@input="handlerSearch"
			/>
		</div>

		<div class="search-list__content">
			<ul class="search-list__list">
				<li
					v-for="(item, index) in items"
					:key="index"
					class="search-list__item result-item"
					:class="{ active: selected.slug === item.slug }"
					@click="select(item)"
				>
					<span class="result-item__title">{{ item.title }}</span>
					<span class="result-item__text">{{ getInfo(item) }}</span>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { createRequest } from '@/api/requestBuilder'
import requestConfigs from '@/api/requestConfigs'
import debounce from 'lodash/debounce'
import { formatCash } from '@/utils/formatMoney'
import { simbol, money } from '@/enums/moneyСollections.js'

// TODO:
// Добавить скролинг пагинацию

export default {
	props: {
		projects: {
			type: Array,
			default: () => []
		},
		selectArea: {
			type: Array,
			default: () => []
		},
		selectProject: {
			type: Object,
			default: () => ({
				slug: ''
			})
		}
	},
	data() {
		return {
			selected: {},
			results: [],
			loading: false
		}
	},
	beforeMount() {
		if (this.selectProject.slug) {
			this.selected = { ...this.selectProject }
		}
	},
	computed: {
		items() {
			// Выводим в первую очередь выбранные, потом уже все остальные
			return this.results?.length ? this.results : this.projects
		}
	},
	methods: {
		handlerSearch: debounce(function (evt) {
			if (evt.target.value?.length > 3) {
				this.getSearchList(evt.target.value)
			} else {
				this.results = []
			}
		}, 500),
		getSearchList(payload) {
			if (this.loading) {
				return
			}

			const queryPayload = { search: payload }

			if (this.selectArea?.length) {
				queryPayload.region_slug = this.selectArea
					.map((itm) => itm.slug)
					.join(',')
			}

			this.loading = true
			createRequest(requestConfigs.GETProjectList, {
				queryPayload: { ...queryPayload }
			})
				.then(({ response }) => {
					this.results = [...response.items]
				})
				.finally(() => {
					this.loading = false
				})
		},
		getInfo(payload) {
			if (payload.price_from) {
				const price = payload.price_from[money[this.$store.state.activeMoney]]
				return `${payload.announcement_count} ${this.$t('home.offers')} from ${
					simbol[this.$store.state.activeMoney]
				}${price ? formatCash(price) : 0}`
			} else {
				return `${payload.units_count} ${this.$t('home.offers')}`
			}
		},
		select(payload) {
			if (payload.slug === this.selected.slug) {
				this.selected = {}
			} else {
				this.selected = { ...payload }
			}

			this.$emit('select', this.selected)
		}
	}
}
</script>
